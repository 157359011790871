<template>
  <div class="page-container">
    <el-row :gutter="40">
      <el-col :span="8">
        <div class="grid-content bg-purple" @mouseenter="hoverIndex = 1" @mouseleave="hoverIndex = 0">
          <div class="report-icon">
            <i class="iconfont icon-zongshu" :class="hoverIndex == 1 ? 'iconfont-blue-hover' : 'iconfont-blue'"></i>
          </div>
          <div class="report-message">
            <div class="report-title">{{ year }}年活动总场次</div>
            <div class="report-number">{{ fullYearActiveNum }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple" @mouseenter="hoverIndex = 2" @mouseleave="hoverIndex = 0">
          <div class="report-icon">
            <i class="iconfont icon-daishenhe" :class="hoverIndex == 2 ? 'iconfont-yellow-hover' : 'iconfont-yellow'"></i>
          </div>
          <div class="report-message">
            <div class="report-title">本月填报活动场次</div>
            <div class="report-number">{{ monthPreactiveNum }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple" @mouseenter="hoverIndex = 4" @mouseleave="hoverIndex = 0">
          <div class="report-icon">
            <i class="iconfont icon-yiwancheng" :class="hoverIndex == 4 ? 'iconfont-green-hover' : 'iconfont-green'"></i>
          </div>
          <div class="report-message">
            <div class="report-title">本月实办活动场次</div>
            <div class="report-number">{{ monthActiveNum }}</div>
          </div>
        </div>
      </el-col>
      <!-- <el-col :span="6">
        <div class="grid-content bg-purple" @mouseenter="hoverIndex = 3" @mouseleave="hoverIndex = 0">
          <div class="report-icon">
            <i class="iconfont icon-weitongguo" :class="hoverIndex == 3 ? 'iconfont-red-hover' : 'iconfont-red'"></i>
          </div>
          <div class="report-message">
            <div class="report-title">未通过活动</div>
            <div class="report-number">6</div>
          </div>
        </div>
      </el-col> -->
    </el-row>
    <div class="charts-layout" v-loading="chartsLoading" element-loading-text="数据统计中" element-loading-spinner="el-icon-loading">
      <div id="line-charts-home" style="width: 100%; height: 100%;"></div>
    </div>
    <div class="store-report">
      <div class="store-report-tool">
        <div class="store-report-title">
          {{ areaReportTitle }}
          
        </div>
        <div style="font-size: 14px;">
          选择月份：
          <el-date-picker
            v-model="selectDate"
            type="month"
            size="mini"
            style="width: 150px;margin-right: 30px;"
            placeholder="请选择"
            format="yyyy 年 MM 月"
            :disabled="areaReportLoading"
            @change="changeSelectDate"
            >
          </el-date-picker>
          <el-button type="success" size="mini" v-if="!areaReportLoading" :loading="downloadLoading" icon="el-icon-download" @click="exportExcel">下载数据</el-button>
        </div>
      </div>
      <el-table 
        :data="areaReportData"  
        v-loading="areaReportLoading" 
        element-loading-text="数据统计中" 
        element-loading-spinner="el-icon-loading" 
        id="tableId" 
        height="400"
      >
        <el-table-column label="地区" prop="name"></el-table-column>
        <el-table-column label="当月已报活动数量" prop="preActiveNum"></el-table-column>
        <el-table-column label="全年累计已报数量" prop="preActiveTotalNum"></el-table-column>
        <el-table-column label="当月已开活动数量" prop="activeNum"></el-table-column>
        <el-table-column label="全年累计已开数量" prop="activeTotalNum"></el-table-column>
        <el-table-column label="当月活动完成比" prop="ratio">
          <template slot-scope="scope">
            <span v-if="scope.row.ratio > 0">{{ scope.row.ratio }}%</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column label="全年累计活动完成比" prop="ratioTotal">
          <template slot-scope="scope">
            <span v-if="scope.row.ratioTotal > 0">{{ scope.row.ratioTotal }}%</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 底部备案号 -->
    <div class="bottom-beian">
      &copy; Copyright {{ new Date().getFullYear() }} 厅堂邮约 
      <span style="margin-left: 10px;">备案号：</span>
      <a class="beian-link" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">豫ICP备2023011655号-1</a>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import area from '@/utils/area'
import FileSaver from "file-saver"
import * as XLSX from "xlsx"
export default {
  name: 'HuijiliWebIndex',
  data() {
    return {
      hoverIndex: 0,
      echart: null,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      activeList: [], // 所有实办活动数据
      preActiveList: [], // 所有填报活动数据
      fullYearActiveNum: 0, // 全年活动总场次
      storeList: [], // 所有网点数据
      monthActiveNum: 0, // 当月实办活动场次
      monthPreactiveNum: 0, // 当月填报活动场次
      yearActiveNum: [], // 全年实办活动图表数据
      yearPreactiveNum: [], // 全年填报活动图表数据
      areaReportData: [], // 根据地区统计的数据
      downloadLoading: false,
      areaReportTitle: '',
      chartsLoading: false,
      areaReportLoading: false,
      tableDate: '',
      selectDate: new Date(), // 用户选择的日期
    };
  },
  created() {
    this.initData()
  },
  mounted() {
    this.echart = echarts.init(document.getElementById('line-charts-home'));
    this.setChartsOptions()
    this.areaReportTitle = `${ this.year }年${ this.month }月地区活动完成情况`
  },
  watch: {
    activeList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          // this.onReportData()
        }
      }
    },
    preActiveList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          // this.onReportData()
        }
      }
    },
    storeList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          // this.onReportData()
        }
      }
    }
  },
  methods: {
    async initData() {
      this.chartsLoading = true
      this.areaReportLoading = true
      // 查询所有填报活动
      try {
        const preactivityResp = await this.$http({
          method: 'post',
          url: 'preactivity_findall',
          params: {
            state: '1'
          }
        })
        if (preactivityResp.errno == 0) {
          this.preActiveList = preactivityResp.data.data
        } else {
          this.$message.error('填报活动请求超时')
          this.chartsLoading = false
          this.areaReportLoading = false
        }
      } catch(e) {
        this.chartsLoading = false
        this.areaReportLoading = false
        this.$message.error('网络异常，填报活动获取失败')
      }

      // 查询所有实办活动
      try {
        const activityResp = await this.$http({
          method: 'post',
          url: 'activity_findall',
          params: {
            state: '2'
          }
        })
        if (activityResp.errno == 0) {
          this.activeList = activityResp.data.data
        } else {
          this.$message.error('实办活动请求超时')
          this.chartsLoading = false
          this.areaReportLoading = false
        }
      } catch(e) {
        this.chartsLoading = false
        this.areaReportLoading = false
        this.$message.error('网络异常，实办活动获取失败')
      }

      // 查询所有网点
      try  {
        const storeResp = await this.$http({
          method: 'post',
          url: 'store_findall'
        })
        if (storeResp.errno == 0) {
          this.storeList = storeResp.data.data
          sessionStorage.setItem('storeList', JSON.stringify(this.storeList))
        } else {
          this.$message.error('网点查询请求超时')
          this.chartsLoading = false
          this.areaReportLoading = false
        }
      } catch(e) {
        this.chartsLoading = false
        this.areaReportLoading = false
        this.$message.error('网络异常，网点数据获取失败')
      }

      try {
        this.areaReportData = []
        // 按地区统计数据
        const areaResp = await this.$http({
          method: 'post',
          url: 'area_findall'
        })
        if (areaResp.errno == 0) {
          areaResp.data.data.forEach(item => {
            // if (!item.name.includes('测试')) {
            //   this.areaReportData.push({
            //     name: item.name,
            //     activeNum: 0,
            //     activeData: [],
            //     preActiveNum: 0,
            //     preActiveData: [],
            //     ratio: 0
            //   })
            // }
            this.areaReportData.push({
              name: item.name,
              activeNum: 0,
              activeData: [],
              preActiveNum: 0,
              preActiveData: [],
              ratio: 0
            })
          })
        } else {
          this.$message.error('地区统计请求超时')
          this.chartsLoading = false
          this.areaReportLoading = false
        }
      } catch(e) {
        this.chartsLoading = false
        this.areaReportLoading = false
        this.$message.error('网络异常，地区统计数据获取失败')
      }
      this.onReportData()
    },
    // 统计数据
    onReportData() {
      const result = this.activeList.length > 0 && this.preActiveList.length > 0 && this.storeList.length > 0
      if (!result) {
        return
      }

      this.monthActiveNum = 0 // 当月实办活动场次
      this.monthPreactiveNum = 0 // 当月填报活动场次
      this.yearActiveNum = [] // 全年实办活动图表数据
      this.yearPreactiveNum = [] // 全年填报活动图表数据

      // 全年活动总场次
      this.fullYearActiveNum = this.activeList.filter(item => {
        return item.activitydate.startsWith(this.year)
      }).length

      // 当月填报活动场次
      const filterPreactiveList = this.preActiveList.filter(item => {
        return item.month == `${this.year}/${this.month}`
      })
      filterPreactiveList.forEach(item => {
        if (item.details) {
          const details = JSON.parse(item.details)
          details.forEach(obj => {
            this.monthPreactiveNum += obj.num
          })
        }
      })
      
      // 当月实办活动场次
      this.monthActiveNum = this.activeList.filter(item => {
        return item.activitydate.startsWith(`${this.year}/${this.month}/`)
      }).length

      // 全年统计图表数据
      for (let i = 1; i <= 12; i++) {
        const activeNum = this.activeList.filter(item => {
          return item.activitydate.startsWith(`${this.year}/${i}/`)
        }).length
        this.yearActiveNum.push(activeNum)
        let preactiveCount = 0
        this.preActiveList.forEach(item => {
          if (item.month == `${this.year}/${i}` && item.details) {
            const details = JSON.parse(item.details)
            details.forEach(obj => {
              preactiveCount += obj.num
            })
          }
        })
        this.yearPreactiveNum.push(preactiveCount)
      }
      this.chartsLoading = false
      this.areaReportLoading = false
      this.setChartsOptions()
      this.setAreaReportData()
    },
    setChartsOptions() {
      // this.echart = echarts.init(document.getElementById('line-charts-home'));
      this.echart.setOption({
        title: {
          text: `${this.year}年活动数量统计`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['填报场次', '实办场次']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          // feature: {
          //   saveAsImage: {}
          // }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '填报场次',
            type: 'line',
            data: this.yearPreactiveNum,
            smooth: true
          },
          {
            name: '实办场次',
            type: 'line',
            data: this.yearActiveNum,
            smooth: true
          }
        ]
      })
    },
    // 渲染地区统计数据
    setAreaReportData(year, month) {
      if (!year) {
        year = this.year
      }
      if (!month) {
        month = this.month
      }
      // 实办活动添加地区字段
      this.activeList.forEach(active => {
        this.storeList.forEach(store => {
          if (active.storename == store.storename) {
            active.area = store.area
          }
        })
      })
      
      const preActiveList = []
      this.preActiveList.forEach(prea => {
        // if (prea.details && prea.month == `${year}/${month}`) {
        if (prea.details && prea.month.startsWith(`${year}/`)) {
          const details = JSON.parse(prea.details)
          details.forEach(item => {
            item.month = prea.month
            if (item.num > 0) {
              preActiveList.push(item)
            }
          })
        }
      })

      this.areaReportData.forEach(area => {
        area.preActiveData = []
        area.activeData = []
        area.preActiveTotalData = []
        area.activeTotalData = []
        // 当月已报活动
        preActiveList.forEach(pre => {
          if (area.name == pre.area && pre.month == `${year}/${month}`) {
            area.preActiveData.push(pre)
          }
        })
        // 全年累计已报
        preActiveList.forEach(pre => {
          if (area.name == pre.area && pre.month.startsWith(`${year}/`)) {
            area.preActiveTotalData.push(pre)
          }
        })

        // 当月已开活动
        this.activeList.forEach(active => {
          if (area.name == active.area && active.activitydate.startsWith(`${year}/${month}`)) {
            area.activeData.push(active)
          }
        })
        // 全年累计已开
        this.activeList.forEach(active => {
          if (area.name == active.area && active.activitydate.startsWith(`${year}`)) {
            area.activeTotalData.push(active)
          }
        })
      })

      // 统计已报、已开数据
      this.areaReportData.forEach(area => {
        area.preActiveNum = 0
        area.preActiveData.forEach(prea => {
          area.preActiveNum += prea.num
        })

        area.preActiveTotalNum = 0
        area.preActiveTotalData.forEach(prea => {
          area.preActiveTotalNum += prea.num
        })

        area.activeNum = area.activeData.length

        area.activeTotalNum = area.activeTotalData.length
      })

      // 统计完成比
      this.areaReportData.forEach(item => {
        if (item.preActiveNum > 0) {
          item.ratio = Math.round(item.activeNum / item.preActiveNum * 10000) / 100
        }

        if (item.preActiveTotalNum > 0) {
          item.ratioTotal = Math.round(item.activeTotalNum / item.preActiveTotalNum * 10000) / 100
        }
      })

      // 按完成比降序排列
      this.areaReportData.sort((a,b) => {
        return b.ratio - a.ratio
      })
    },
    // 导出Excel
    exportExcel() {
        //转换成excel时，使用原始的格式
        this.downloadLoading = true
				var xlsxParam = { raw: true };
				let fix = document.querySelector(".el-table__fixed");
				let wb;
        //判断有无fixed定位，如果有的话去掉，后面再加上，不然数据会重复
				if (fix) {
				  wb = XLSX.utils.table_to_book(
				  document.querySelector("#tableId").removeChild(fix),xlsxParam
				  );
				  document.querySelector("#tableId").appendChild(fix);
				} else {
				  wb = XLSX.utils.table_to_book(document.querySelector("#tableId"),xlsxParam);
				}
				var wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
				});
				try {
				FileSaver.saveAs(
					new Blob([wbout], { type: "application/octet-stream" }),`${this.areaReportTitle}.xlsx`); //文件名
          this.downloadLoading = false
				} catch (e) {
          this.downloadLoading = false
				  if (typeof console !== "undefined") console.log(e, wbout);
				}
        this.downloadLoading = false
				return wbout
    },
    // 用户选择日期事件
    changeSelectDate(date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      this.areaReportTitle = `${ year }年${ month }月地区活动完成情况`
      this.setAreaReportData(year, month)
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  .grid-content {
    height: 120px;
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 2px rgba($color: #000000, $alpha: 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .report-icon {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      
      .iconfont {
        font-size: 48px;
      }

      .iconfont-blue {
        color: #409EFF;
      }
      .iconfont-blue-hover {
        color: #FFFFFF;
        width: 80px;
        height: 80px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: #409EFF;
      }
      .iconfont-green {
        color: #67C23A;
      }
      .iconfont-green-hover {
        color: #FFFFFF;
        width: 80px;
        height: 80px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: #67C23A;
      }
      .iconfont-red {
        color: #F56C6C;
      }
      .iconfont-red-hover {
        color: #FFFFFF;
        width: 80px;
        height: 80px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: #F56C6C;
      }
      .iconfont-yellow {
        color: #E6A23C;
      }
      .iconfont-yellow-hover {
        color: #FFFFFF;
        width: 80px;
        height: 80px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: #E6A23C;
      }
    }

    .report-message {
      flex: 1;
      margin-left: 10px;

      .report-title {
        color: #8C8C8C;
        font-size: 14px;
      }
      .report-number {
        font-size: 24px;
        margin-top: 10px;
        color: #666666;
        font-weight: 600;
      }
    }
  }

  .charts-layout {
    width: 100%;
    height: 400px;
    margin: 30px 0px;
    border-radius: 3px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 15px 20px;
  }
  
  .store-report {
    width: 100%;
    border-radius: 3px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 15px 20px 30px;
    margin-bottom: 50px;

    .store-report-tool {
      margin: 15px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .store-report-title {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

/* 修改滚动条样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
	width: 4px;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
	background: rgba(0, 0, 0, 0.15);
}

.bottom-beian {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #666;
  font-size: 14px;
}
.beian-link {
  text-decoration: none;
  color: #666;
}
</style>