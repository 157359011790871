export default [
  '鹤壁业务区',
  '安阳业务区',
  '焦作业务区',
  '开封业务区',
  '洛阳业务区',
  '漯河业务区',
  '南阳业务区',
  '平顶山业务区',
  '濮阳业务区',
  '商丘业务区',
  '新乡业务区',
  '信阳业务区',
  '许昌业务区',
  '郑州市业务区',
  '周口业务区',
  '驻马店业务区',
  '招商郑州业务区'
]